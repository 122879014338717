import Modal from '../src/components/modal/Modal'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Modal />
      </header>
    </div>
  );
}

export default App;
